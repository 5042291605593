import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout.component';
import Seo from '../components/seo/seo.component';
import Content from '../components/content/content.component';
import Comments from '../components/comments/comments.component';
import NextPrevious from '../components/next-previous/next-previous.component';

const Lecture = ({ data, pageContext }) => {
  const {
    html,
    frontmatter: { title, description, coverImage, post, slug, tags },
  } = data.markdownRemark;
  const { previous, next } = pageContext
  const SEOConfig = {
    title,
    description,
    image: coverImage,
    postUrl: `/lectures/${slug}`,
    tags,
  };
  return (
    <Layout SEOConfig={SEOConfig}>
      <Seo {...SEOConfig} />
      <Content
        post={post}
        title={title}
        description={description}
        html={html}
        coverImage={coverImage}
      />
      <NextPrevious
        post={post}
        postLinkPrevious={previous?.frontmatter.slug}
        postLinkNext={next?.frontmatter.slug}
        postDescriptionPrevious={previous?.frontmatter.description}
        postDescriptionNext={next?.frontmatter.description}
      />
      <Comments />
    </Layout>
  );
};

export default Lecture;

export const query = graphql`
  query ($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        coverImage
        post
        slug
        tags
      }
    }
  }
`;
